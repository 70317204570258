import React from 'react';
import { Box, Typography, FormControl, Card, MenuItem, Select, Divider } from '@mui/material';
import { getApolloDiseaseDetection, setApolloDiseaseDetectionGapStatus, setApolloDiseaseDetectionRecStatus } from '../../api';
import useFetch from '../../hooks/useFetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

const DiseaseDetection = ({ patientId, username, token }) => {
    const { data: patient, loading, error } = useFetch(getApolloDiseaseDetection, patientId, { username, token});
    const [hccGaps, setHccGaps] = React.useState([]);
    const [hccRecommendations, setHccRecommendations] = React.useState([]);

    React.useEffect(() => {
        if (patient && patient.HccGaps) {
            setHccGaps(patient.HccGaps);
        }
        if (patient && patient.HccRecommendations) {
            setHccRecommendations(patient.HccRecommendations);
        }
    }, [patient]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error.message}</Typography>;

    const handleAckGaps = (event, diagnosisCode) => {
        setApolloDiseaseDetectionGapStatus(patientId, { username, token}, diagnosisCode, 'ACK');
        setHccGaps(prevState => prevState.map(gap =>
            gap.DiagnosisCode === diagnosisCode ? { ...gap, Status: 'ACK' } : gap
        ));
    };

    const handleChangeGaps = (event, diagnosisCode) => {
        const { value } = event.target;
        setApolloDiseaseDetectionGapStatus(patientId, { username, token}, diagnosisCode, value);
        setHccGaps(prevState => prevState.map(gap =>
            gap.DiagnosisCode === diagnosisCode ? { ...gap, Status: value } : gap
        ));
    };

    const handleAckRecommendations = (event, diagnosisCode) => {
      setApolloDiseaseDetectionRecStatus(patientId, { username, token}, diagnosisCode, 'ACK');
      setHccRecommendations(prevState => prevState.map(rec =>
        rec.DiagnosisCode === diagnosisCode ? { ...rec, Status: 'ACK' } : rec
      ));
    };

    const handleChangeRecommendations = (event, diagnosisCode) => {
        const { value } = event.target;
        setApolloDiseaseDetectionRecStatus(patientId, { username, token}, diagnosisCode, value);
        setHccRecommendations(prevState => prevState.map(rec =>
            rec.DiagnosisCode === diagnosisCode ? { ...rec, Status: value } : rec
        ));
    };

    if (patient.ActiveGap === 0 && patient.ActiveRec === 0) {
      return (<div></div>)
    }

    let gapComponent = ""
    let recCompoent = ""
    let hasInfo = 0
    if (patient.ActiveGap === 1) {
        gapComponent = (<div><Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="teal"
            color="white"
            p={1}
            borderRadius="4px 4px 0 0"
        >
            HCC Gaps
        </Box>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {hccGaps.map(gap => (
                        <TableRow key={gap.DiagnosisCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '4px' }} component="th" scope="row">
                                {gap.DiagnosisCode} (HCC {gap.HCC}): {gap.Description}
                            </TableCell>
                            <TableCell sx={{ padding: '4px' }} align="right">
                                <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                  <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    onChange={(event) => handleAckGaps(event, gap.DiagnosisCode)}
                                  />
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Divider sx={{ height:10 }}/></div>)
    } 

    if (patient.ActiveRec === 1) {
        hasInfo = 1
        recCompoent = (<div>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor="teal"
              color="white"
              p={1}
              borderRadius="4px 4px 0 0"
            >
              HCC Recommendations
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  {hccRecommendations.map((rec, index) => (
                    <React.Fragment key={rec.DiagnosisCode}>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell sx={{ padding: '3px', bgcolor: 'darkgray' }} component="th" scope="row"></TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell sx={{ padding: '8px', bgcolor: 'lightgray' }} component="th" scope="row">
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>
                            {rec.DiagnosisCode} (HCC {rec.HCC}):
                        </Typography> {rec.Description}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell sx={{ padding: '8px' }} align="left">
                          <Box display="flex" alignItems="center">
                            <Box sx={{ flexGrow: 1 }}>
                              {rec.Reason}
                            </Box>
                            <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                  <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    onChange={(event) => handleAckRecommendations(event, rec.DiagnosisCode)}
                                  />
                                </FormControl>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>)
    }
    return (
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: '#d5dbdb', margin: '0 !important', minHeight: 'unset !important', '& .MuiAccordionSummary-content': { margin: '5px 0 !important' } }}
                >
                    <Typography variant="h6">Disease Detection</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#eaeded', padding: '12px' }}>
                    {gapComponent}
                    {recCompoent}
                </AccordionDetails>
            </Accordion>
            <Divider sx={{ height:15 }}/>
        </div>
    );
}

export default DiseaseDetection;
