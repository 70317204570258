import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { getAuth, login, resetPassword } from '../../api';
import MuiAlert from '@mui/material/Alert';
import TaskQueue from './TaskQueue';

import { TextField, Box, Button, Typography, Snackbar } from '@mui/material';
import { ControlPointDuplicateSharp } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CodingHome = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [beta, setBeta] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [state, setState] = React.useState({
    openAlert: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [widgetWidth, setWidgetWidth] = React.useState('430');

  const { vertical, horizontal, openAlert } = state;

  useEffect(() => {
    const token = Cookies.get('authToken');
    const username = Cookies.get('username');
    if (token && token !== '') {
      setAuthToken(token);
      setUsername(username);
    }
  }, []);

  useEffect(() => {
    const authenticate = async () => {
      if (authToken) {
        const token = authToken;

        try {
          const auth = await getAuth({ username, token });
          if (auth.token === '') {
            Cookies.remove('authToken');
            setAuthToken('');
          } else {
            setBeta(auth.beta);
          }
        } catch (error) {
          console.error('Error during authentication:', error);
        }
      }
    };

    authenticate();
  }, [authToken, username]);

  const handleLogin = async () => {
    try {
      const data = await login({ 'user': username, 'password': password });
      if (data.token) {
        if (process.env.NODE_ENV === 'production') {
          Cookies.set('username', username, { expires: 5, sameSite: 'None', secure: true });
          Cookies.set('authToken', data.token, { expires: 5, sameSite: 'None', secure: true }); // Expires in 5 days hours
        } else {
          Cookies.set('username', username, { expires: 5 })
          Cookies.set('authToken', data.token, { expires: 5 }); // Expires in 5 days hours
        }
        setAuthToken(data.token);
        setBeta(data.beta);
        if (data.reset && data.reset === 1) {
          setResetPass(true);
          setPassword('');
        } else {
          setResetPass(false);
        }
      }
    } catch (error) {
      console.error('Login failed', error);
      setState({ ...state, openAlert: true });
    }
  };

  const handleReset = async () => {
    try {
      const data = await resetPassword({ username, password, authToken });
      if (data.token) {
        if (process.env.NODE_ENV === 'production') {
          Cookies.set('authToken', data.token, { expires: 1, sameSite: 'None', secure: true }); // Expires in 24 hours
        } else {
          Cookies.set('authToken', data.token, { expires: 1 }); // Expires in 24 hours
        }
        setAuthToken(data.token);
        setBeta(data.beta);
        if (data.reset && data.reset === 1) {
          setResetPass(true);
        } else {
          setResetPass(false);
        }
      } else {
        console.error('Login failed', data.message);
        setState({ ...state, openAlert: true });
      }
    } catch (error) {
      console.error('Login failed', error);
      setState({ ...state, openAlert: true });
    }
  };

  const handleLogout = () => {
    Cookies.remove('authToken');
    setAuthToken('');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setState({ ...state, openAlert: false });
  };

  if (resetPass) {
    return (
      <div style={{ maxWidth: '430px', margin: '0 auto' }}>
        <Typography variant="h5">Reset Password</Typography>
        <TextField
          label="New Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleReset}>
          Set New Password
        </Button>
      </div>
    );
  }

  if (authToken !== '') {
    let module = {
      title: 'Report a problem',
      feedback_description: 'Problem Description',
      module_category: 'AppFeedback',
      module_name: 'Problems',
      module_item_key: '',
    };

    if (beta) {      
      return (
        <div style={{ maxWidth: widgetWidth + 'px', margin: '0 auto', marginBottom: '50px' }}>
        <TaskQueue username={username} token={authToken} />
      </div>
      );
    }

    return (
      <div style={{ maxWidth: widgetWidth + 'px', margin: '0 auto', marginBottom: '50px' }}>
      <TaskQueue username={username} token={authToken} />
    </div>
    );
  }

  if (!authToken || authToken === '') {
    return (
      <div style={{ maxWidth: widgetWidth + 'px', margin: '0 auto' }}>
        <Typography variant="h5">Login</Typography>
        <TextField
          label="Username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
        <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
          <Alert onClose={handleClose} severity="error">
            Login failed
          </Alert>
        </Snackbar>
      </div>
    );
  }
};

export default CodingHome;
