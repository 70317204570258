import React from 'react';
import { Box, Typography, Divider, Select, MenuItem  } from '@mui/material';
import { getApolloHeader } from '../../api';
import { useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import { setApolloHeaderStatus }  from '../../api';

const TaskQueue = ({ username, token }) => {

    const { data: patient, loading, error } = useFetch(getApolloHeader, '2204', { username, token});
    const [priority, setPriority] = useState('');

    useEffect(() => {
      if (patient && patient.Priority) {
        setPriority(patient.Priority);
      }
    }, [patient]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error.message}</Typography>;
    
    // Handle select change
    const handlePriorityChange = (event) => {
      setApolloHeaderStatus('2204', { username, token}, event.target.value)
      setPriority(event.target.value);
    };

    return (
        <div>
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="teal"
            color="white"
            p={1.5}
            borderRadius="4px 4px 0 0"
          >
            <Box display="flex" alignItems="center">
              <Box
                component="img"
                src="/icon.jpg" // Replace with the path to your icon
                alt="Icon"
                width={25}
                height={40}
                mr={1}
              />
              <Box>
                <Typography variant="body1">{patient.PatientName} #{patient.PatientID}</Typography>
              </Box>
            </Box>
            <Box textAlign="left" sx={{ minWidth: 78, ml: 1 }}>
              <Typography variant="body2" sx={{ height: '20px' }}>{patient.Eligibility}</Typography>
              <Typography variant="body2" sx={{ height: '24px' }}>{patient.Dual}</Typography>
            </Box>
            <Box textAlign="left" sx={{ minWidth: 135 }}>
              <Typography variant="body2" component="div" sx={{ height: '20px' }}>vRAF: {patient?.VRAF}</Typography>
              <Typography variant="body2" component="div">Risk:&nbsp;
                <Select
                  value={priority}
                  onChange={handlePriorityChange}
                  sx={{
                    fontSize: '0.75rem', // Even smaller font size (12px)
                    padding: '0px 0px',  // Reduce padding
                    height: '24px',      // Set a smaller height
                    lineHeight: '1',   // Tighter line height
                    width: '95px',       // Smaller width
                    color: priority === 'High Risk' ? 'orange' : priority === 'Very High Risk' ? '#ff6d6d' : 'inherit',
                    fontWeight: priority === 'High Risk' || priority === 'Very High Risk' ? 'bold' : 'inherit',
                    marginLeft: 0,       // Add some spacing from the label
                  }}
                  inputProps={{
                    sx: {
                      padding: '5px',         // Override padding for internal elements
                      height: '24px',         // Set input height
                      lineHeight: 'normal',   // Adjust line height inside the input
                    }
                  }}
                >
                  <MenuItem value="Very High Risk">Very High</MenuItem>
                  <MenuItem value="High Risk">High</MenuItem>
                  <MenuItem value="Low-to-Moderate Risk">Low / Med</MenuItem>
                </Select>
              </Typography>
            </Box>
            <Box textAlign="left">
            </Box>
        </Box>
        <Divider />
        <Box my={0}>
          <Typography variant="body2">Recommended Next Appointment: {patient.RecommendedNextAppt}</Typography>
        </Box>
        <Divider sx={{ height:15 }}/>
        </div>
    )
}

export default TaskQueue;
